@import "tailwindcss";

@theme {
  --scale-flip: -1;
}

/*
  The default border color has changed to `currentColor` in Tailwind CSS v4,
  so we've added these compatibility styles to make sure everything still
  looks the same as it did with Tailwind CSS v3.

  If we ever want to remove these styles, we need to add an explicit border
  color utility to any element that depends on these defaults.
*/
@layer base {
  *,
  ::after,
  ::before,
  ::backdrop,
  ::file-selector-button {
    border-color: var(--color-gray-200, currentColor);
  }
}

@layer base {
  html {
    @apply dark:bg-gray-900 dark:text-white;
  }
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  a {
    @apply text-blue-600 dark:text-blue-300 underline hover:text-blue-400;
  }
  code {
    @apply bg-slate-100 text-pink-400 p-0.5;
  }
  .loading .data,
  .loading .data a {
    @apply animate-pulse rounded-full bg-gray-200 text-gray-200 dark:bg-gray-700 dark:text-gray-700;
  }
}
